import React, { useContext, useState } from 'react'
import logo from "../assests/logo.png"
import "./Navbar.css";
import navbar from "../datas/navbarli"
import activeImage from "../svgs/active.svg"
import Button from "./Button"
import SelectLang from './SelectLang';
import { TabIndex } from '../context/TabContext';

const Navbar = ({ setSelectedIndex }) => {

  const { isActive, setIsActive, ScrollToTopHandler } = useContext(TabIndex);
  const showActive = true;

  const [showMenu, setShowMenu] = useState(false);
  const handleLaunchApp = () => (
    window.location = "https://lovelyswap.com/"
  )


  const handleActive = (index) => {
    setIsActive(index)
    setSelectedIndex(index)
    setShowMenu(!showMenu)
  }

  return (
    <>
      <div className="main-container-navbar">
        <div className="navbar-container">
          <div className="left-navbar" onClick={ScrollToTopHandler}>
            <img src={logo} alt="error-logo" className='' />
            <p>LOVELY<span>SWAP</span></p>
          </div>
          <div className="mid-navbar">
            <ul>
              {showActive && <img src={activeImage} alt="error-active" style={{ left: `${isActive === 1 ? isActive * 72 : isActive === 2 ? isActive * 3.2 * 27 : isActive === 3 ? isActive * 3.5 * 28 : (isActive * 24) - 3}px`, transition: ".2s ease" }} />}
              {
                navbar.map((element, index) => (
                  <li key={index} style={{ color: index === isActive ? "#ED628B" : "" }} onClick={() => handleActive(index)}>{element.tag}</li>
                ))
              }
            </ul>
          </div>
          <div className="right-navbar">
            <SelectLang />
            <Button className="Navbar-btn">
              <a href="https://lovelyswap.com/" target='_blank'><span>launch app</span></a>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
