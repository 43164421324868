import React from "react";
import settings from "../svgs/settings.svg";
import scalable from "../svgs/scalable.svg";
import security from "../svgs/security.svg";
import appImage from "../svgs/imageLogo.svg";
import "./AppDescMob.css"
import Button from "./Button";


const AppDescMob = () => {

  return (
    <>
      <div className="main-app-mobile-desc">
        <div className="mobile-app-heading-span">
          <span>
            What is
            <br /> Lovely Swap?
          </span>
        </div>

        <div className="mobile-apps-cards-description">
          <div className="right-app-container">
            <div className="flex-end-left-container">
              <div className="mobile-background-container">
                <img src={settings} alt="security-setting" />
                <span>Automation</span>
              </div>

              <div className="mobile-background-container">
                <img src={security} alt="error-security" />
                <span>Security</span>
              </div>
            </div>

            <div className="flex-end-right-container">
              <div className="mobile-background-container">
                <img src={appImage} alt="error-app" />
                <span>Zero fee dex</span>
              </div>

              <div className="mobile-background-container">
                <img src={scalable} alt="security-scalable" />
                <span>SCALABILITY</span>
              </div>
            </div>
          </div>

        </div>
          <div className="mobile-paragraphs">
            <p className="mobile-p-top">
              LOVELY SWAP is a key stage in the process of the Decentralized
              finance (DeFi) infrastructure developing and updating. As DeFi
              evolves, more and more projects integrate with decentralized
              alternatives to traditional financial institutions, in particular,
              Decentralized Exchange (DEX).
            </p>
            <p className="mobile-p-mid">
              LOVELY SWAP is a highly modified version of Uniswap (the largest
              DEX operating on the Ethereum blockchain). which has been adapted
              to unlock and handle various important financial capabilities.
            </p>
          </div>

          <Button className="gtstarted" ><a href="https://doc.lovelyswap.com" target="_blank">Learn More</a></Button>
      </div>
    </>
  );
};

export default AppDescMob;
