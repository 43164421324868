import React from 'react'

const ImageField = ({src, altText, className, onClick}) => {
  return (
    <>
    <img src={src} alt={altText} className={className} onClick={onClick}/>
    </>
  )
}

export default ImageField