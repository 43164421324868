import cls from "../svgs/cls.svg"
import Allbridge from "../svgs/Allbridge.png"
import ONTOwallet from "../svgs/ONTOwallet.png"
import assure from "../svgs/assure.png"
import bloxroute from "../svgs/bloxroute.png"
import guarda from "../svgs/guarda.png"
import okX from "../svgs/okX.png"
import BitGet from "../svgs/BitGet.png"
import NaBox from '../svgs/nabox.png'
import LovelyChain from '../svgs/LovelyChain.png'
import Binance from '../svgs/Binance.png'
import Polygon from '../svgs/Polygon.png'
import spaceid from '../svgs/spaceid.png'
import EchoooWallet from '../svgs/Echoo Flat Logo.png';
import FoxWallet from '../svgs/Fox Wallet Logo For Web.png';
import AmazeWallet from '../svgs/Amaze Wallet.png';
import XLayerLogoWhite from '../svgs/XLayer_Logo_White.png'
import BaseSYmbol from '../svgs/Base_Symbol_White.png';
import ChainGPT from '../svgs/ChainGPT.png';
import Coin98 from '../svgs/Coin98.png';


const partnersArray = [
    {
        name:"Polygon",
        imgElem: Polygon,
        detail: "",
        category: "BlockChain"
    },
    {
        name:"Spaceid",
        imgElem: spaceid,
        detail: "",
        category: "Other"
    },
    {
        name:"ChainGPT",
        imgElem: ChainGPT,
        detail: "https://x.com/chain_gpt/status/1796530389667713119?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Other"
    },
    {
        name:"OKX Wallet",
        imgElem: okX,
        detail: "https://x.com/okxweb3/status/1775821570440913308?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Wallet"
    },
    {
        name:"BitGet Wallet",
        imgElem: BitGet,
        detail: "",
        category: "Wallet"
    },
    {
        name:"ONTO Wallet",
        imgElem: ONTOwallet,
        detail: "https://x.com/ontowallet/status/1615634867076857856?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Wallet"
    },
    {
        name:"Assure Wallet",
        imgElem: assure,
        detail: "https://x.com/assure_pro/status/1608030770765115394?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Wallet"
    },
    {
        name:"Echooo Wallet",
        imgElem: EchoooWallet,
        detail: "https://x.com/echooo_wallet/status/1788402910629626351?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Wallet"
    },
    {
        name:"BloXroute",
        imgElem: bloxroute,
        detail: "https://x.com/bloxroutelabs/status/1755651314305167408?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Data"
    },
    {
        name:"Guarda Wallet",
        imgElem: guarda,
        detail: "https://x.com/guardawallet/status/1601248209153949698?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Wallet"
    },
    {
        name:"Coin 98",
        imgElem: Coin98,
        detail: "https://x.com/coin98_wallet/status/1799366844429832274?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Wallet"
    },
    {
        name:"Nabox Wallet",
        imgElem: NaBox,
        detail: "",
        category: "Wallet"
    },
    {
        name:"LovelyChain",
        imgElem: LovelyChain,
        detail: "",
        category: "BlockChain"
    },
    {
        name:"BNB Smart Chain",
        imgElem: Binance,
        detail: "",
        category: "BlockChain"
    },
    {
        name:"X Layer",
        imgElem: XLayerLogoWhite,
        detail: "",
        category: "BlockChain"
    },
    {
        name:"Base Chain",
        imgElem: BaseSYmbol,
        detail: "",
        category: "BlockChain"
    },
    {
        name:"CLS",
        imgElem: cls,
        detail: "https://x.com/coinliquidity/status/1600149844156248066?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Other"
    },
    {
        name:"Allbridge",
        imgElem: Allbridge,
        detail: "https://x.com/allbridge_io/status/1615364354622332928?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Other"
    },
    {
        name:"Fox Wallet",
        imgElem: FoxWallet,
        detail: "https://x.com/foxwallet/status/1793471365623972033?s=46&t=PFCD9sD1jPtHkXLnfLcP2g",
        category: "Wallet"
    },
    {
        name:"Amaze Wallet",
        imgElem: AmazeWallet,
        detail: "",
        category: "Wallet"
    }
]

export default partnersArray;
