import React, { useContext } from "react";
import "../css/Home.css";
import settings from "../svgs/settings.svg";
import scalable from "../svgs/scalable.svg";
import security from "../svgs/security.svg";
import appImage from "../svgs/imageLogo.svg";
import midImage from "../assests/mid-image.svg";
import Button from "../components/Button";
import AppDescMob from "../components/AppDescMob";
import pencil from '../svgs/pencil.svg';
import Lottie from 'react-lottie';
import { Bounce, toast } from 'react-toastify';
import BigBubble from "../bubbles/big_buble (1).json";
import SmallBubble from "../bubbles/Small_buble.json"
import Partners from "../components/Partners";
import { TabIndex } from "../context/TabContext";

const Home = ({ props, selectedIndex }) => {
  const handleCopyText = (e) => {
    e.preventDefault()
    var copyText = "info@lovely.finance";
    navigator.clipboard.writeText(copyText);
    toast.success('Copied!', {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };
  const { ScrollToTopHandler } = useContext(TabIndex);

  const ScrollToAbout = document.getElementById('About');
  const ScrollToInquiry = document.getElementById('Inquiry-container');
  const ScrollToPartner = document.getElementById('partner');

  switch (selectedIndex) {
    case 1:
      ScrollToAbout.scrollIntoView({ behavior: "smooth", block: "start" });
      break;
    
      case 2:
        ScrollToPartner.scrollIntoView({ behavior: "smooth", block: "start" });
      break;

      
    case 3:
      ScrollToInquiry.scrollIntoView({ behavior: "smooth", block: "start" })
      break;

    default:
      ScrollToTopHandler();
      break;
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SmallBubble,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: BigBubble,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  return (
    <>
      <div className="main-Container">
        <div className="BubbleAnimTop">
          <Lottie
            options={defaultOptions}
            height={400}
            width={400}
          />
        </div>
        <div className="BubbleAnimBottom">
          <Lottie
            options={defaultOptions}
            height={400}
            width={400}
          />
        </div>

        <div className="card-main-container" id="Home">
          <div className="top-main-content">
            <strong>Zero Fee DEX</strong>
            <p>
              <span className="by">by</span>
              <span className="lvl">LOVELY</span>
              <span className="swp">FINANCE</span>
            </p>
          </div>
          <div className="mid-images">
            <img src={midImage} alt="midImage" className="midImage" />
          </div>

          <div className="bottom-buttons">
            <Button className="gtstarted"><a href="https://lovelyswap.com">Launch App</a></Button>
            <Button className="whitepaper documents-btn"><a href="https://doc.lovelyswap.com">Documentation</a></Button>
          </div>
        </div>
      </div>

      <div className="milestones">
        <div className="milestones-innerDiv">
          <p>upcoming Milestones</p>
          <div className="flex">
            <div className="div-milestones-info">
              <Button className="milestones-btn" >01</Button>

              <div className="main-svg-div">
                <span>Lovely Launchpad</span>
                <p>
                  The independent ILO Platform. Helpful for new projects &
                  startups.
                </p>
              </div>
            </div>

            <div className="div-milestones-info">
            <Button className="milestones-btn" >02</Button>
              <div className="main-svg-div">
                <span>P2P</span>
                <p>
                  The convenient crypto-to-fiat exchange - the first ever
                  implemented on DEX platforms.
                </p>
              </div>
            </div>

            <div className="div-milestones-info long">
            <Button className="milestones-btn" >03</Button>
              <div className="main-svg-div">
                <span>Limit Order Trading</span>
                <p>
                  The Limit Order allows you to set the rate you want to
                  exchange your currency for another. If that rate becomes
                  available, the exchange will occur automatically. With the
                  Limit Order in place, you won't need to monitor the market -
                  you can sit back and wait for the trade to happen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {
        props ? <AppDescMob  /> :
          <div className="app-description-main-container" id="About">
            <div className="inner-app-container">
              <div className="left-app-container">
                <span>
                  What is
                  <br /> Lovely Swap?
                </span>

                <p className="p-top">
                  Lovely Finance introduces Lovely Swap, an Automated Market Maker (AMM), representing a significant innovation within the launchpad ecosystem. This breakthrough aims to create a robust and sustainable niche in the decentralized finance (DeFi) landscape for our valued community. Our primary objective is to enhance our investors' capital growth by providing additional financial resources, attracting, and engaging new communities to expand our network.
                </p>
                <p className="p-mid">
                  Lovely Swap is the cornerstone of our DeFi ecosystem upgrade, serving as the foundational pillar of our infrastructure. By integrating various projects, the decentralized exchange (DEX) ensures seamless connectivity and harmonization of all components, driving efficiency and cohesion within the DeFi environment.
                </p>

                <Button className="gtstarted"><a href="https://menafn.com/1105323643/One-Stop-Platform-Of-CEX-And-DEX-LOVELY-SWAP">learn more</a></Button>
              </div>
              <div className="right-app-container">
                <div className="BubbleAnimTopRightContainer">
                  <Lottie
                    options={defaultOptions2}
                    height={400}
                    width={400}
                  />
                </div>
                <div className="BubbleAnimTopRightContainer2">
                  <Lottie
                    options={defaultOptions2}
                    height={400}
                    width={400}
                  />
                </div>
                <div className="flex-end-left-container">
                  <div className="background-container">
                    <img src={settings} alt="security-setting" />
                    <span>Automation</span>
                  </div>

                  <div className="background-container">
                    <img src={security} alt="error-security" />
                    <span>Security</span>
                  </div>
                </div>

                <div className="flex-end-right-container">
                  <div className="background-container">
                    <img src={appImage} alt="error-app" />
                    <span>Zero fee dex</span>
                  </div>

                  <div className="background-container">
                    <img src={scalable} alt="security-scalable" />
                    <span>SCALABILITY</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }

      <Partners id="partner" />



      <div className="Inquiry-container" id="Inquiry-container">
        <div className="inquiry-inner" onClick={handleCopyText}>
          <button className="inquiry-btn btn"><img src={pencil} alt="error-pencil" /></button>
          <div className="inquiry-svg-div">
            <div>
              <span>Inquiry</span>
              <p>info@lovely.finance</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
