import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Navbar from "./components/Navbar"
import Home from './Pages/Home';
import Footer from "./components/Footer"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [mobileView, setMobileView] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const updateWindowDimensions = () => {
    const newWidth = window.innerWidth;
    if (newWidth <= 576) {
      setMobileView(true)
    }
    else {
      setMobileView(false)
    };
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
  }, window.innerWidth);

  return (
    <div className="App">
      <ToastContainer limit={1}/>
      <BrowserRouter>
        <Navbar props={mobileView} setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} />
        <Routes>
          <Route path='/' element={<Home props={mobileView} selectedIndex={selectedIndex} />} />
        </Routes>
        <Footer props={mobileView} />
      </BrowserRouter>


    </div>
  );
}

export default App;
