import React from "react";
import "./Footer.css";
import Button from "./Button";
import logo from "../assests/logo.png";
import telegram from "../svgs/telegram.svg";
import twitter from "../svgs/x.svg";
import { Link } from "react-router-dom";
import arrowRight from "../svgs/arrowRight.svg";

const Footer = ({ props }) => {
  return (
    <>
      <div className="main-container-footer">
        <div className="footer-container">
          <div className="footer-left">
            <div className="footer-logo">
              <img src={logo} alt="error-logo" className="" />
              <p>
                LOVELY<span>SWAP</span>
              </p>
            </div>
            <div className="social-media">
              <button>
                <img
                  src={telegram}
                  alt=""
                  onClick={() => (window.location = "https://t.me/lovelyswap")}
                />
              </button>
              <button>
                <img
                  src={twitter}
                  alt=""
                  onClick={() =>
                    (window.location = "https://x.com/lovely_swap")
                  }
                />
              </button>
            </div>
            <p className="Copyright">
              Copyright © 2024 LovelySwap All Rights Reserved
            </p>
          </div>
          <div className="footer-mid">
            <Link to="" className="bold">
              Support
            </Link>
            <div>
              <Link to="" className="support-menu">
                Media kit
              </Link>
              <Link to="" className="support-menu">
                Official verification
              </Link>
            </div>
          </div>
          <div className="footer-right">
            <Link to="" className="bold">
              Subscribe
            </Link>
            <p>
               Subscribe now for exclusive updates and development news from <b>Lovely Swap.</b>
            </p>
            <div className="subscribe">
              <button className="at btn">@</button>
              <input type="text" placeholder="" />
              {props ? (
                <button className="subscribe-btn btn">
                  <img src={arrowRight} alt="error-pencil" />
                </button>
              ) : (
                <Button className="subscribe-btn">SUBSCRIBE</Button>
              )}
            </div>
          </div>

          <p className="Copyright-mobile">
            Copyright © 2024 LovelySwap All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
