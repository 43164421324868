import React from 'react'
import "./Button.css"

const Button = ({value, onClick, isDisabled, className, children}) => {
  return (
    <>
    <button onClick={onClick} className={`${className} btn`}>{children}</button>
    </>
  )
}

export default Button