import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Partners.css";
import partnersArray from "../datas/partners";
// import partnersOptions from "../datas/partnersOptions";
import ImageField from "./ImageField";



const menuItems = [
  "Show All", "BlockChain", "Data", "Wallet", "Other"
]
const Partners = ({id}) => {
  const [activeIndexPartner, setActiveIndexPartner] = useState(0);
  const [items, setItems] = useState(partnersArray);
  const filterItem = (categItem) => {
    const updateItems = partnersArray.filter((curElem) => {
      return curElem.category === categItem;
    });

    setItems(updateItems);
  };


  const handleFilterSelection = (element) => {
    if(element === "Show All"){
      setItems(partnersArray);
    }
    else{
      filterItem(element)
    }
  }



  return (
    <>
      <div className="partner-div" id={id}>
        <span>our partners</span>

        <div className="parent-div-partners">
          <div className="menu-div-partners">
            {
              menuItems.map((element, key) => (
                <span key={key} className={activeIndexPartner === key ? "active-partners" : ""} onClick={() => { handleFilterSelection(element); setActiveIndexPartner(key) }} >
                  {element}
                </span>
              ))
            }
          </div>

          <div className="partners-flexbox">
            {items.map((element, key) => {
              const { name, imgElem, detail } = element;

              return (
                <div className="partners-image-div" key={key}>
                  <div className="service-box">
                    <div className="service-icon ">
                      <div className="front-content">
                        <ImageField
                          src={imgElem}
                          altText="error-icon-partner"
                        />
                        <span>{name}</span>
                      </div>
                    </div>
                    <div className="service-content">
                      <div>
                        <Link
                          to={detail}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <button>More Details</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
