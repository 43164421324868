const navbar = [
    {
        tag: "Home",
        path: "/"
    },
    {
        tag: "About",
        path: "/about"
    },
    {
        tag: "Partners",
        path: "/partners"
    },
    {
        tag: "Contact US",
        path: "/contact"
    },
]
export default navbar;