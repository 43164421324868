import React, { createContext, useEffect, useState } from 'react';

export const TabIndex = createContext("");

const TabContext = ({children}) => {

    const [isActive, setIsActive] = useState(0);
    const ScrollToTopHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsActive(0);
    }
    useEffect(() => {
        ScrollToTopHandler();
    },[])
  return (
    <>
      <TabIndex.Provider value={{isActive, setIsActive, ScrollToTopHandler}}>
        {children}
      </TabIndex.Provider>
    </>
  )
}

export default TabContext
